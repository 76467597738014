import { AuthAxios } from "../../global-config/HttpConfig";
import { ApiURIs } from "../../global-config/ApiList";

export default {
  GetAddress() {
    return AuthAxios.get(ApiURIs.UniversalGetAddress);
  },
  autoComplete(distId: number) {
    return AuthAxios.get(
      `${ApiURIs.UniversalGetAutoComplete}?distId=${distId}`
    );
  },
  sendSms(phone: string) {
    return AuthAxios.get(`${ApiURIs.UniversalSms}?phone=${phone}`);
  },
  verifyEmail(data: any) {
    return AuthAxios.post(
      ApiURIs.SendEmailVerificationCode,
      JSON.stringify(data)
    );
  }
};
