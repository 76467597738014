

























































import { Vue, Component, Prop } from "vue-property-decorator";
import { IHospitalDetailViewModel } from "../../models/HospitalDetailViewModel";

@Component({
  components: {},
})
export default class DiagnosticCard extends Vue {
  @Prop({ default: {} }) diagnostic: IHospitalDetailViewModel;
  public isShowAppoitment: boolean = false;

  public diagnosticId: number = 0;

  public get diagnosticData(): IHospitalDetailViewModel {
    return this.diagnostic;
  }

  closeModal(value: any) {
    this.isShowAppoitment = value;
  }
  // book(diagnosticId: number) {
  //   this.diagnosticId = diagnosticId;
  //   this.isShowAppoitment = true;
  // }
}
