
























































import { Vue, Component, Watch } from "vue-property-decorator";
import ProfileWidget from "@/common-app/components/ProfileWidget.vue";
import DiagnosticCard from "./components/DiagnosticCard.vue";
import ListWidgets from "./components/ListWidgets.vue";
import HospitalService from "../service/HospitalService";
import { IHospitalDetailViewModel } from "../models/HospitalDetailViewModel";
import { IHospitalDetail } from "../models/HospitalDetail";

@Component({
  components: {
    DiagnosticCard,
    ListWidgets,
  },
})
export default class DiagnosticList extends Vue {
  public fee: number = 0;
  public title: string = "Sharafat";
  public diagnostic: IHospitalDetailViewModel[] = [];
  //public alldiagnostics: IDiagnosticDetailsViewModel[] = [];
  public alldiagnostics: IHospitalDetail[] = [];
  public isConcateSearchData = true;
  public spId: number = 0;
  public distId: number = 0;
  rows = 10;
  currentPage = 1;
  perPage = 3;

  @Watch("$route", { immediate: true, deep: true })
  onUrlChange(newVal: any) {
    if (newVal.query.id && newVal.query.distId) {
      this.distId = Number(newVal.query.distId) || 0;
      this.spId = Number(newVal.query.id) || 0;
      HospitalService.GetSearch(this.distId, this.spId).then((res) => {
        //this.doctors = res.data;
        this.alldiagnostics = res.data;
        this.rows = this.alldiagnostics.length;
        this.paginate(this.perPage, 0);
      });
    } else {
      HospitalService.GetList().then((res) => {
        //this.doctors = res.data;
        this.alldiagnostics = res.data;
        this.alldiagnostics = this.alldiagnostics.filter((s) => s.isActive == 1);
        this.rows = this.alldiagnostics.length;
        this.paginate(this.perPage, 0);
      });
    }
  }
  created() {
    this.rows = 10;
    this.currentPage = 1;
    this.perPage = 10;
    this.paginate(this.perPage, 0);
  }

  paginate(page_size: number, page_number: number) {
    // this.diagnostic = this.alldiagnostics.slice(
    this.alldiagnostics = this.alldiagnostics.slice(page_number * page_size, (page_number + 1) * page_size);
  }

  onPageChanged(page: number) {
    this.paginate(this.perPage, page - 1);
  }

  handelSearch(data: any) {
    if (this.isConcateSearchData) {
      this.diagnostic = [];
    }
    if (data.type == 1) {
      if (data.isChecked) {
        HospitalService.getDoctorSearch(data.type, data.searchKey, this.distId, this.spId).then((res) => {
          this.diagnostic = this.getUnique(this.diagnostic.concat(res.data), "id");
        });
      } else {
        this.diagnostic = this.diagnostic.filter((s) => s.searchKey != data.searchKey);
      }
    }

    // time event
    if (data.type == 2) {
      HospitalService.getDoctorSearch(data.type, data.searchKey, this.distId, this.spId).then((res) => {
        this.diagnostic = this.diagnostic.filter((s) => s.type != data.type);
        this.diagnostic = this.getUnique(this.diagnostic.concat(res.data), "id");
      });
    }

    // fee event
    if (data.type == 3) {
      HospitalService.getDoctorSearch(data.type, data.searchKey, this.distId, this.spId).then((res) => {
        this.diagnostic = this.diagnostic.filter((s) => s.type != data.type);
        this.diagnostic = this.getUnique(this.diagnostic.concat(res.data), "id");
      });
    }

    if (data.type == 4) {
      HospitalService.getDoctorSearch(data.type, data.searchKey, this.distId, this.spId).then((res) => {
        this.alldiagnostics = this.alldiagnostics.filter((s) => s.type != data.type);
        this.alldiagnostics = this.getUnique(this.alldiagnostics.concat(res.data), "id");
        this.alldiagnostics = [];
        this.alldiagnostics = res.data;
      });
    }

    if (data.type == 5) {
      HospitalService.getDoctorSearch(data.type, data.searchKey, this.distId, this.spId).then((res) => {
        this.diagnostic = this.diagnostic.filter((s) => s.type != data.type);
        this.diagnostic = this.getUnique(this.diagnostic.concat(res.data), "id");
      });
    }

    if (data.type == 6) {
      HospitalService.getDoctorSearch(data.type, data.searchKey, this.distId, this.spId).then((res) => {
        this.diagnostic = this.diagnostic.filter((s) => s.type != data.type);
        this.diagnostic = this.getUnique(this.diagnostic.concat(res.data), "id");
      });
    }

    this.isConcateSearchData = false;
  }

  removeDuplicate(doctors: IHospitalDetailViewModel[]): IHospitalDetailViewModel[] {
    return doctors.filter((obj, index, arr) => {
      return (
        index ===
        arr.findIndex((obj) => {
          return JSON.stringify(obj) === JSON.stringify(obj);
        })
      );
    });
  }

  getUnique(arr: any[], comp: any) {
    const unique = arr
      .map((e: any) => e[comp])

      // store the keys of the unique objects
      .map((e: any, i: number, final: any[]) => final.indexOf(e) === i && i)

      // eliminate the dead keys & store unique objects
      .filter((e: any) => arr[e])
      .map((e: any) => arr[e]);

    return unique;
  }
}
