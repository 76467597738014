import { AuthAxios } from "../../global-config/HttpConfig";
import { ApiURIs } from "../../global-config/ApiList";

export default {
  GetList() {
    return AuthAxios.get(ApiURIs.HospitalList);
  },
  GetTopList() {
    return AuthAxios.get(ApiURIs.HospitalTopList);
  },
  GetSearch(distId: number, specializationId: number) {
    return AuthAxios.get(
      `${
        ApiURIs.DrGetSearch
      }?distId=${distId}&specializationId=${specializationId}`
    );
  },

  getDoctorSearch(
    type: number,
    searchKey: string,
    districtId: number = 0,
    specializationId: number = 0
  ) {
    return AuthAxios.get(
      `${
        ApiURIs.HospitalSearch
      }?type=${type}&searchKey=${searchKey}&districtId=${districtId}&specializationId=${specializationId}`
    );
  },

  GetDetailsById2(id: number) {
    return AuthAxios.get(`${ApiURIs.HospitalDetailsById}/${id}`);
  },
  GetDetailsById(id: number) {
    return AuthAxios.get(
      `${
        ApiURIs.HospitalDetailsById
      }?id=${id}`
    );
  },
};
